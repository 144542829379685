@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Lato:ital,wght@0,400;1,300&family=Roboto+Condensed:wght@300&family=Roboto:ital,wght@0,400;1,300;1,900&display=swap');
.navbar-brand {
    color: #4c4d4c;
    font-size: 2.5em;
    font-family: 'Cinzel Decorative', serif;
    font-weight:400;
    vertical-align: middle;
  }
  .search-bar {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  
  .search-bar.open {
    max-height: 50px;
    opacity: 1;
  }
  .selected-row {
    background-color: #f2dcdc;
    font-weight:bold;
  }
  .cartImage{
    width: 36px;
  }

  /* custom.css or inlined styles */
.custom-offcanvas .offcanvas-body {
  height: 80vh; /* Make the Offcanvas take up more vertical space */
  overflow-y: auto; /* Ensure scrollability if content overflows */
}

.search-results {
  padding: 10px;
}

.search-results .card {
  cursor: pointer;
}

.search-results .card img {
  max-height: 150px;
  object-fit: cover;
}

  @media (max-width: 600px) {
    .navbar-brand {
        font-size: 20px;
    }
    .navbar-brand img{
        width: 45px;
        height: 32px;
    }
  }

  .quantity-input {
    border: 1px solid #ddd;
    border-radius: 0;
    margin-left: 0
  }
  
  .quantity-button-left {
    border-radius: 5px 0 0 5px; /* Rounded corners on left/right sides */
    margin-right: 0;
  }
  
  .quantity-button-right{
    border-radius: 0 5px 5px 0; /* Rounded corners on left/right sides */
    margin: 0;
  }
  .quantity-form{
    padding:0;
    margin:0;
  }